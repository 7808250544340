.failed {
  padding: 2rem 4rem;
  padding-bottom: 4rem;
  padding-top: 0;
  @media (max-width: 500px) {
    padding: 2rem 1rem;
    padding-bottom: 2rem;
    // padding-top: 0;
  }

  &__icon {
    width: 40%;
    display: block;
    margin: auto;
  }
  &__message {
    font-size: 1rem;
    margin: auto;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    text-align: center;
    color: #acacac;
    width: fit-content;

    b {
      display: flex;
      align-items: center;
      color: #000;
      font-weight: 500;
      margin-left: 0.2rem;
      cursor: pointer;
    }
  }

  &__buttons {
    width: 100%;
    max-width: 400px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-top: 1rem;

    button {
      width: 100%;
      height: 55px;
      max-width: 300px;
      background: #4438cb;
      border-radius: 6px;
      font-family: inherit;
      font-size: 1rem;
      color: #fff;
      display: block;
      margin: 0 auto;
      margin-top: 1.5rem;
      cursor: pointer;

      &:nth-child(2) {
        background: #e2e2e2;
        color: #5d5d5d;
      }
    }
  }
}
