.success {
  padding: 2rem 4rem;
  padding-bottom: 4rem;
  padding-top: 0;
  @media (max-width: 500px) {
    padding: 2rem 1rem;
    padding-bottom: 2rem;
    
  }

  &__icon {
    width: 40%;
    display: block;
    margin: auto;
  }
  &__message {
    font-size: 1rem;
    margin: auto;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    text-align: center;
    color: #acacac;
    width: fit-content;

    b {
      display: flex;
      align-items: center;
      color: #000;
      font-weight: 500;
      margin-left: .2rem;
      cursor: pointer;
    }
  }
  button {
    width: 100%;
    height: 55px;
    max-width: 300px;
    background: #4438cb;
    border-radius: 6px;
    font-family: inherit;
    font-size: 1rem;
    color: #fff;
    display: block;
    margin: 0 auto;
    margin-top: 2rem;
    cursor: pointer;
  }
}
