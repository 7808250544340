.redirect {
  background: #16161b;
  color: #fff;
  min-height: 100vh;
  font-weight: 500;
  p {
    font-size: 1.2rem;
    text-align: center;
    margin: auto;
    width: fit-content;
    margin-top: 20vh;
    color: #fff;
    font-weight: 500;
    display: flex;
    align-items: center;

  }
}
