.home {
  background: #16161b;
  color: #fff;
  min-height: 100vh;
  &__main {
    width: 100%;
    height: calc(100vh - 600px);
    min-height: 600px;
    // display: grid;
    // align-items: center;
    padding: 0 6rem;
    margin-top: 15vh;
    @media (max-width: 1024px) {
      padding: 0 2rem;
    }
    @media (max-width: 600px) {
      padding: 0 1.5rem;
    }
    @media (max-width: 355px) {
      padding: 0 1rem;
    }
    h3 {
      text-align: center;
      color: #fff;
      margin-top: 20vh;
    }
  }
}
