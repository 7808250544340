.options {
  padding: 2rem 4rem;
  padding-bottom: 4rem;
  @media (max-width: 500px) {
    padding: 2rem 1rem;
    padding-bottom: 4rem;
  }

  h2{
    font-size: 2rem;
    font-weight: 600;
    color: #444;
    line-height: 1;
    @media(max-width: 600px){
        font-size: 1.5rem;
    }
  }

  &__items {
    margin-top: 3rem;
  }

  &__item {
    background: #f4f4f4;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    height: 70px;
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 20px;
    padding: 0 1rem;
    cursor: pointer;
    margin-top: 1rem;
    p{
        color: #A2A2A2;
        font-size: .9rem;
    }

    &__ring{
          width: 20px;
          height: 20px;
          border: 2px solid #D1D1D1;
          border-radius: 50%;
    }
  }
}
