.wrapper {
  width: 100%;
  height: auto;
  height: 100vh;
  background: #0000007e;
  overflow-y: auto;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;

  &__cancel {
    width: 30px;
    height: 30px;
    margin-top: 1rem;
    border-radius: 50%;
    margin-left: auto;
    margin-right: 1.5rem;
    margin-top: 1rem;
    cursor: pointer;
  }

  &__box {
    position: relative;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: grid;
    place-content: center;
    height: auto;
    min-height: 100vh;
    padding: 4rem 1rem;
    @media (max-width: 500px) {
      padding: 2rem 1rem;
    }
  }

  &__main {
    background: #ffffff;
    border-radius: 32px;
    min-height: 200px;
    height: fit-content;
    //max-height: calc(100vh - 4rem);
    width: calc(100vw - 1rem);
    max-width: 700px;
   
    // position: relative;

    animation: showModal 0.2s ease-out 1;

    @keyframes showModal {
      from {
        transform: scale(1) translateY(50px);
        opacity: 0;
      }
      to {
        transform: scale(1) translateY(0px);
        opacity: 1;
      }
    }

    @media (max-width: 500px) {
      border-radius: 15px;
    }
  }
}
