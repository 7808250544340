.select {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f3f3f3;
  padding: 0 1rem;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 1rem;
  h6 {
    margin: 0;
    color: #949494;
    font-size: 0.7rem;
    font-weight: 500;
  }
  h5 {
    margin: 0;
    font-size: 0.9rem;
    font-weight: 500;
  }

  &__dropdown {
    display: flex;
    align-items: center;
    background: #dedede;
    padding: 0.3rem 0.5rem;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    h5 {
      color: #6d6d6d;
      margin: 0;
      margin-right: 0.2rem;
      font-size: 0.7rem;
    }

    &__options {
      position: absolute;
      background: #fff;
      top: 2rem;
      right: 0;
      box-shadow: 0px 4px 21px -5px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      // background: red;
      padding: 1rem;
      width: 150px !important;

      animation: showOptions 0.2s ease-in-out 1;
      z-index: 2;

      @keyframes showOptions {
        from {
          opacity: 0;
          transform: translateY(10px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    &__option {
      display: grid;
      align-items: center;
      grid-template-columns: 30px 1fr;

      margin-top: 1rem;
      &:nth-child(1) {
        margin-top: 0;
      }

      img {
        width: 20px;
      }
      p {
        margin: 0;
        font-size: 0.9rem;
        font-weight: 500;
      }
    }
  }

  &__content{
    display: flex;
    align-items: center;
    margin-top: .1rem;
    img {
      width: 18px;
      margin-right: .2rem;
    }
  }
}
